import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import EnvData from '../components/EnvData';
import {PRIMARY_COLOR_3} from '../constants';
import PlainPageContainer from '../components/PlainPageContainer';
import Separator from '../components/Separator';

export default function PolitikaPrivatnostiPage({location}) {
	return (
		<EnvData>
			<ThePage uri={location.pathname} />
		</EnvData>
	);
}

PolitikaPrivatnostiPage.propTypes = {
	// eslint-disable-next-line
	location: PropTypes.object
};

const ThePage = function ({uri}) {
	return (
		<Layout title="Politika zaštite privatnosti" description="Pročitajte našu politiku privatnosti koje se držimo" uri={uri}>
			<Nav background={PRIMARY_COLOR_3} />
			<PlainPageContainer>
				<h1>Politika zaštite privatnosti</h1>
				<p>Ovo su načela prema kojima poslujemo u Splendido Solutions-u.</p>
				<p>
					Splendido Solutions d.o.o., OIB: 42172723674 sa sjedištem u Varaždinu, Stjepana Vukovića 8B (u daljnjem tekstu "Firma") kao voditelj
					obrade podataka pripremio je za vas ovu Politiku u namjeri da vas upoznamo s postupkom obrade i zaštite vaših osobnih podataka.
				</p>
				<h2>Na kakvu se vrstu obrade podataka odnosi ova Politika?</h2>
				<p>
					Ova Politika odnosi se na obradu osobnih podataka osoba koje posjećuju naše mrežne stranice: qr-cjenik.hr, app.qr-cjenik.hr,
					nas.qr-cjenik.hr, web.qr-cjenik.hr, danasnji.menu, cjenik.today, najbolji.bar, najbolji.pub i najbolja.pizza (u daljnjem tekstu:
					"Korisnici");
				</p>
				<h2>Koje vrste podataka o vama prikupljamo</h2>
				<p>
					Vaše osobne podatke prikupljamo i obrađujemo kada stupite u interakciju s nama ispunjavajući obrasce na našim mrežnim stranicama,
					obraćanjem telefonom, putem elektroničke pošte ili na neki drugi način. To uključuje i podatke koje upisujete kad se prijavljujete
					za korištenje naše mrežne stranice, kada se registrirate, itd. Podaci o kojima je riječ mogu biti vaše ime, adresa, adresa e-pošte
					i broj telefona ili podaci o vašem pravnom subjektu (npr. OIB vaše firme i slično). Ne prikupljamo i ne obrađujemo osjetljive
					podatke povezane s vama (primjerice, podatke o vašem zdravstvenom stanju ili rasnom ili etničkom podrijetlu) osim u slučaju (1) da
					su takvi osjetljivi podaci važni za određenu svrhu za koju i prikupljamo osobne podatke; (2) da je to naša zakonska obveza i/ili
					(3) da ste nam posebno za to izričito dali suglasnost.
				</p>
				<p>
					Ostavite li dobrovoljno vaše osobne podatke, takve podatke ćemo obrisati isključivo ako to od nas zatražite putem službenog kanala
					komunikacije ili ako smo dužni tako postupiti prema zakonu.
				</p>
				<p>Svaki put kad posjetite našu internetsku stranicu, prikupljamo sljedeće vrste podataka o vama:</p>
				<ul>
					<li>
						tehničke podatke, a to primjerice mogu biti IP adresa računala kojom ste se spojili na Internet, podatak o vašem korisničkom
						imenu, vrsti i verziji preglednika, vremenskoj zoni, tipu i nazivu mobilnog uređaja. Ovi se podaci za naše potrebe mogu
						prikupljati i obrađivati putem kolačića treće strane.
					</li>
					<li>
						podatke o vašem posjetu, a to primjerice može biti cijeli Uniform Resource Locator (URL), clickstream dolaska na našu mrežnu
						stranicu, kroz našu mrežnu stranicu i odlaska s naše mrežne stranice.
					</li>
				</ul>
				<h2>Na kakvu se vrstu obrade podataka odnosi ova obavijest?</h2>
				<p>
					Vaše osobne podatke obrađujemo iz više razloga tehničke, administrativne i operativne naravi, primjerice kako bi sadržaji bili
					prezentirani na način koji je najučinkovitiji za vas i vaše računalo; kako bismo poboljšali našu mrežnu stranicu i njenu
					funkcionalnost; za potrebe vođenja naše mrežne stranice; za potrebe internih postupaka poput rješavanja tehničkih problema,
					analize podataka, testiranja, istraživanja, statistike i drugih uvida; za potrebe marketinga koji uključuje ciljani marketing kako
					bismo vam omogućili sadržaje koji će vas više zanimati, te u sklopu naših nastojanja da naša mrežna stranica bude sigurna.
				</p>
				<p>
					U nekim slučajevima, vaše podatke obrađujemo samo uz vašu privolu. U tom slučaju ćemo prilikom uzimanja vaših osobnih podataka
					posebno zatražiti vašu izričitu suglasnost za taj postupak. Nakon toga u svakom trenutku imate mogućnost uskratiti svoju
					suglasnost slanjem elektroničke pošte na kontakt[at]qr-cjenik.hr. Međutim, uskrata daljnje suglasnosti ne utječe na zakonitost
					obrade podataka koji su uz vašu suglasnost obrađeni do trenutka kad ste uskratili daljnju suglasnost.
				</p>
				<p>
					U slučajevima kada od vas tražimo osobne podatke kako bismo ispunili svoje zakonske ili ugovorne obveze, vi ste nam dužni ustupiti
					te podatke. To znači da u slučaju da ne dobijemo zatražene osobne podatke, možda nećemo biti u mogućnosti provesti naš ugovorni
					odnos ili ispuniti naše obveze. U svim drugim slučajevima sami odlučujete o tome hoćete li nam dati svoje osobne podatke i niste
					nam ih dužni dati.
				</p>
				<p>
					Vaše osobne podatke poput podataka o identitetu, podataka o kontaktu i podataka o prebivalištu prikupljamo za potrebe eventualnih
					ostvarivanja naših prava ili potraživanja. Pravni temelj za obradu ove vrste podataka naš je zakoniti interes da ostvarimo svoja
					prava u eventualnim sporovima.
				</p>
				<p>
					Za potrebe davanja usluga, dostave roba ili isplata u skladu s odgovarajućim ugovorima, možemo obrađivati vaše osobne podatke
					poput identiteta, podataka za kontakt, podataka o bankovnom računu. Pravni temelj ove obrade podataka jest ispunjenje ugovornih
					obveza prema Korisnicima (posebno pretplatnicima koji plaćaju mjesečno) kao ugovornoj stranci ili ispunjenje naših zakonskih
					obveza.
				</p>
				<p>
					Vaše osobne podatke, uz vašu privolu, možemo obrađivati kako bismo vas obavijestili, ili omogućili trećim stranama da vas
					obavijeste o novostima za koje smatramo da bi vas mogle zanimati.
				</p>
				<h2>Kako i kome otkrivamo vaše osobne podatke</h2>
				<p>Firma ne prodaje vaše osobne podatke trećim stranama.</p>
				<p>
					Uvid u osobne podatke omogućujemo tijelima državne vlasti i/ili organima za provedbu zakona u slučajevima kada je to određeno
					zakonom ili radi zaštite naših prava uključujući naših uvjeta korištenja te za potrebe zaštite naših zakonitih interesa
					(uključujući i zakonita prava drugih osoba) sukladno zakonskim propisima koji su na snazi. Vaše osobne podatke možemo otkriti i
					trećim stranama, uključujući:
				</p>
				<ul>
					<li>
						davateljima usluga koji pružaju administrativnu, stručnu i tehničku podršku Firmi povezanu s informatičkom tehnologijom,
						sigurnošću i poslovnim resursima
					</li>
					<li>
						davateljima usluga analitike i alata za pretragu koji nam pomažu u poboljšanju i optimalnom funkcioniranju naše mrežne stranice
					</li>
				</ul>
				<p>
					Kada je to potrebno, Firma će dati uvid u vaše osobne podatke stručnim suradnicima izvan Firme (npr. odvjetnicima, knjigovođama,
					revizorima).
				</p>
				<h2>Pohrana vaših osobnih podataka i prijenos u druge zemlje</h2>
				<p>
					Vaši osobni podaci koje Firma prikuplja pohranjuju se u Europskoj Uniji (EU) i Europskom gospodarskom prostoru (EEA). Međutim, za
					podatke koje prikupljaju treće strane putem Kolačića (npr. Google Analytics) općenito se može reći da će putovati iz centra za
					prikupljanje podataka koji je najbliži lokaciji na kojoj je internetski promet nastao. Znači, moguće je da se podaci, uključujući
					promet povezan s oglasima, spremaju na poslužiteljima smještenim u EEA i upućuju na poslužitelje koji nisu smješteni u EEA.
				</p>
				<p>
					Osim toga, vaši osobni podaci mogu se prenijeti i pohraniti izvan Europske Unije i Europskog gospodarskog prostora. U slučaju kad
					se vaši osobni podaci prenose iz vaše zemlje u drugu zemlju, moguće je da se zakoni i propisi koji uređuju zaštitu vaših osobnih
					podataka u zemlji u koju se vaši podaci prenose razlikuju (ili predviđaju manji stupanj zaštite) od zemlje u kojoj ste zaposleni.
				</p>
				<p>
					Naša je namjera da ne prenosimo vaše osobne podatke izvan EEA ako nisu propisane i primjenjuju se odgovarajuće mjere zaštite, koje
					uključuju: (1) odluku Europske komisije o adekvatnosti u vezi s tom zemljom ili zemljama; (2) potvrde o postojanju „sigurnosnog
					štita“; (3) odgovarajući i obvezujući poslovnik; (4) usvojeni kodeks ponašanja uz obvezujuće i provjerljive izjave voditelja
					obrade podataka ili izvršitelja obrade podataka u zemlji koja nije na prostoru EU-a ili EEA; (5) usvojeni mehanizmi provjere i
					potvrde uz obvezujuće i provjerljive izjave voditelja obrade podataka ili izvršitelja obrade podataka u zemlji koja nije na
					prostoru EU-a ili EEA; (6) ugovor u skladu sa standardima EU-a koje je odobrila Europska komisija.
				</p>
				<h2>Vaša prava</h2>
				<p>
					Imate pravo zatražiti od nas potvrdu o tome jesmo li ili nismo obradili vaše osobne podatke, kao i primjerak vaših osobnih
					podataka i/ili priliku da ih ispravite. U nekim okolnostima imate pravo zatražiti da izbrišemo vaše osobne podatke ili, s obzirom
					na pravo na prijenos podataka, da prenesemo dio vaših osobnih podataka vama ili drugim osobama. Također, imate pravo prigovora
					određenoj vrsti obrade vaših osobnih podataka (na primjer, obradi koja se koristi za izravni marketing ili za potrebe donošenja
					odluka isključivo automatskom obradom podataka uključujući profiliranje). Ako smo zatražili od vas suglasnost za pristup vašim
					osobnim podacima, imate pravo uskratiti nam suglasnost bez ikakvih negativnih posljedica po vas. Ako vaše osobne podatke
					obrađujemo zato što je to naš zakoniti interes (kao što smo objasnili u gornjem tekstu), imate pravo i na to uložiti prigovor.
					Osim toga imate pravo ograničiti obradu vaših osobnih podataka u određenim okolnostima.
				</p>
				<p>
					Napominjemo da ta vaša prava u nekim situacijama mogu biti ograničena i da podliježu zakonu i propisima o zaštiti podataka; na
					primjer, vaše pravo na prigovor obradi osobnih podataka može biti ograničeno ako možemo dokazati da imamo zakonski utemeljenih
					razloga za obradu vaših osobnih podataka koji imaju premoć nad vašim interesima. Trebat ćemo potvrdu vašeg identiteta i više
					pojedinosti koje će nam pomoći da odgovorimo na takav vaš zahtjev. Odgovor na vaš zahtjev ne naplaćujemo osim u slučaju da je to
					predviđeno zakonom, a ako postoji zakonski predviđena naknada, ona će biti umjerena i primjerena vašem zahtjevu.
				</p>
				<p>
					Ako želite iskoristiti navedena prava, molim vas da nam se obratite na kontakte navedene u daljnjem tekstu. Nadamo se da ćemo moći
					uspješno odgovoriti na svaki vaš eventualni upit o načinu na koji obrađujemo vaše osobne podatke. Vaše je pravo također obratiti
					se odgovarajućem tijelu koje nadzire zaštitu podataka. Prigovor možete uputiti u državi članici u kojoj živite ili radite ili u
					onoj državi članici u kojoj se po vašim navodima dogodila povreda zakonskih propisa o zaštiti podataka.
				</p>
				<h2>Koliko dugo ćemo zadržati vaše osobne podatke</h2>
				<p>
					Namjera nam je zadržati vaše osobne podatke samo onoliko koliko je potrebno u skladu s našom internom politikom, i ne duže nego
					što je potrebno radi ostvarivanja svrhe sadržane u internoj politici i/ili kako je određeno zakonskim propisima koji su na snazi,
					a koji uključuju zakonski određen najkraći rok zadržavanja podataka, i/ili onoliko koliko je potrebno za ostvarivanje naših
					zakonitih prava (i zakonitih prava drugih strana).
				</p>
				<p>
					Ako je temelj za obradu vaših osobnih podataka vaša suglasnost, te ćemo podatke obrađivati samo tijekom razdoblja za koje ste dali
					suglasnost, osim u slučaju da svoju suglasnost ograničite ili uskratite prije isteka tog roka. Tada prestajemo obrađivati osobne
					podatke na koji se ta uskrata odnosi za odgovarajuće svrhe osim u slučaju da imamo zakonsku obvezu obrađivati te osobne podatke
					i/ili je obrada tih osobnih podataka potrebna radi ostvarivanja naših zakonitih prava (uključujući i zakonita prava drugih
					strana).
				</p>
				<h2>Sigurnost podataka</h2>
				<p>
					Vaše podatke pohranjujemo na naše poslužitelje i na poslužitelje kojima upravlja treća strana (uključujući cloud usluge treće
					strane). Pritom primjenjujemo odgovarajuće tehničke i organizacijske mjere kako bismo zaštitili vaše osobne podatke i spriječili
					neovlašten pristup. S trećim stranama koje nam pružaju usluge hostinga sklopili smo ugovore, a ugovorne obveze uključuju i obveze
					povezane s organizacijskom i tehničkom sigurnošću vaših osobnih podataka. Sav promet je šifriran (npr. pomoću TLS protokola). Vi
					ste sami odgovorni za to da čuvate povjerljivost podataka za verifikaciju (npr. lozinki) koje koristite za pristup dijelovima naše
					mrežne stranice.
				</p>
				<p>
					Prijenos podataka putem interneta nije posve siguran. Premda činimo sve što je u našoj moći da zaštitimo vaše podatke, ne možemo
					vam jamčiti sigurnost podataka koje prenosite na našu mrežnu stranicu; svaki takav prijenos poduzimate na vlastiti rizik. Nakon
					što primimo vaše podatke, koristimo strogo kontrolirane procedure i sigurnosne mjere kojima nastojimo spriječiti neovlašten
					pristup.
				</p>
				<h2>Privatnost djece</h2>
				<p>
					Firma ne prikuplja svjesno osobne podatke osoba mlađih od 16 godina. Ako je roditeljima ili skrbnicima poznato da su njihova djeca
					Firmi ustupila svoje osobne podatke, trebali bi nas o tome odmah obavijestiti. Ako Firma utvrdi da mu je osobne podatke ustupila
					osoba mlađa od 16 godina, ti podaci će se uništiti.
				</p>
				<h2 id="cookies">Kolačići i aplikacije društvenih mreža</h2>
				<h3>Što je kolačić</h3>
				<p>
					Kolačić je informacija spremljena na Vaše računalo od strane web stranice koju posjetite. Kolačići obično spremaju Vaše postavke,
					postavke za web stranicu, kao što su preferirani jezik ili adresa. Kasnije, kada opet otvorite istu web stranicu internetski
					preglednik šalje natrag kolačiće koji pripadaju toj stranici. Ovo omogućava stranici da prikaže informacije prilagođene Vašim
					potrebama.
				</p>
				<p>
					Kolačići mogu spremati širok raspon informacija. Ipak, sve informacije mogu biti spremljene jedino ako Vi to omogućite – web
					stranice ne mogu dobiti pristup informacijama koji im niste omogućili i ne mogu pristupiti drugim datotekama na Vašem računalu.
					Zadane aktivnosti spremanja i slanja kolačića Vama nisu vidljive. Ipak, možete promijeniti postavke internetskog preglednika tako
					da sami odaberete hoćete li odobriti spremanje kolačića, da automatski pri zatvaranju internetskog preglednika obrišete spremljene
					kolačiće i slično.
				</p>
				<h3>Kako onemogućiti kolačiće?</h3>
				<p>
					Isključivanjem kolačića odlučujete da li hoćete dopustiti pohranjivanje kolačića na vašem računalu. Postavke kolačića mogu se
					kontrolirati i konfigurirati u postavkama vašeg internetskog preglednika.
				</p>
				<p>Ako onemogućite kolačiće, nećete moći koristiti neke od funkcionalnosti na web stranicama Firme.</p>
				<h3>Kakve kolačiće koristi Firma i zašto?</h3>
				<p>
					Firma koristi kolačiće s primarnim ciljem kako bi vam naše web stranice omogućile bolje korisničko iskustvo. Kolačići se koriste
					za pamćenje korisničkih podataka i postavki.
				</p>
				<h3>Kakvi još kolačići postoje na Firminim mrežnim stranicama?</h3>
				<p>
					Postoji nekoliko vanjskih servisa koji korisniku spremaju limitirane kolačiće. Ovi kolačići postavljeni su za normalno
					funkcioniranje određenih mogućnosti koje korisnicima olakšavaju pristup sadržaju. Trenutno omogućujemo kolačiće društvenih mreža
					(Facebook) i servisa za mjerenje posjećenosti (Google Analytics).
				</p>
				<h3>Dodatne informacije</h3>
				<p>
					Trenutno postoji nekoliko web-stranica za isključivanje pohranjivanja kolačića za različite servise. Više informacija možete
					saznati na sljedećim poveznicama:
				</p>
				<ul>
					<li>
						<a href="http://www.allaboutcookies.org" rel="noreferrer noopener" target="_blank">
							http://www.allaboutcookies.org
						</a>
					</li>
					<li>
						<a href="http://www.youronlinechoices.eu" rel="noreferrer noopener" target="_blank">
							http://www.youronlinechoices.eu
						</a>
					</li>
					<li>
						<a href="http://www.aboutads.info/choices" rel="noreferrer noopener" target="_blank">
							http://www.aboutads.info/choices
						</a>
					</li>
				</ul>
				<h2>Poveznice na druge mrežne stranice</h2>
				<p>
					Naša mrežna stranica može nuditi poveznice na mrežne stranice koje nisu pod nadzorom Firme. Klik na poveznicu treće strane vodi
					vas na mrežnu stranicu te treće strane. Preporučujemo da se prigodom posjeta mrežnoj stranici na koju vas je odvela poveznica
					upoznate s postavkama privatnosti te mrežne stranice. Mi ne odgovaramo za politiku i praksu drugih društava. Firma nema nadzor i
					ne odgovara za sadržaj, politiku privatnosti i upozorenja o zaštiti privatnosti bilo kojih stranica ili usluga koje pružaju treće
					strane.
				</p>
				<h2>Kontakt</h2>
				<p>
					Ako imate bilo kakvo pitanje ili upit u vezi s postupkom obrade vaših osobnih podataka, ili ako želite iskoristiti bilo koje svoje
					pravo, obratite nam se e-poštom na adresu kontakt[at]qr-cjenik.hr ili pismom na adresu Splendido Solutions, Stjepana Vukovića 8B,
					42000 Varaždin.
				</p>
				<p>Zadnja izmjena: 01.02.2023.</p>
				<p>
					Firma može unositi izmjene i dopune u ovu Politiku, te će takve promjene učiniti dostupnim objavom nove Politike zaštite
					privatnosti na svojim mrežnim stranicama.
				</p>
			</PlainPageContainer>
			<Separator />
			<Footer />
		</Layout>
	);
};

ThePage.propTypes = {
	uri: PropTypes.string
};
